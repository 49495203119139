<template>
    <div >
     <br v-if="isMobile">
      <div class="container-fluid">   
  
         <b-row class="justify-content-center  mt-2" v-if="!loader">
           <b-col cols="11" md="8" v-if=" correcto == 0" >             
            <br><br>   
              <h4 class="mt-5 text-center text-white" v-if="empieza == 0 && correcto == 0">Encuentra los pares en el menor tiempo ¡y suma más puntos!</h4> 
              <h5 class="mt-5 text-center text-white" v-if="empieza == 0 && correcto == 0">¡Tienes 8 intentos!</h5> 
              <!-- <br v-if="empieza == 0 && correcto == 0 && !isMobile"> <br v-if="empieza == 0 && correcto == 0 && !isMobile">  -->
               <br>
              <h4 class="text-center" style="color:#5ab71a;" v-if="empieza == 0 && correcto == 0"><b>¿Listo?</b> Da clic <span class="text-white" style="cursor: pointer;" @click="empezarCronometro()">AQUÍ</span> para iniciar</h4>
              <br>
              <div class="text-center text-white" id="countdown2" v-if="empieza == 1" :style="!isMobile ? ' margin-top:-10px;' : ''">
                  <h2 :style="isMobile ? 'cursor:pointer; font-size:28px;'  : 'cursor:pointer; font-size:30px;'"  id="countdown2">
                      <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:3%; margin-top:-15px;' : 'width:10%; margin-top:-5px;'"> <b>{{ countdown }}</b>
                  </h2>
                   <br v-if="!isMobile"> 
              </div>
               <br v-if="isMobile"> 
           </b-col>
           <b-col cols="11" md="5" v-if="empieza == 1 && !isMobile"> 
              <b-card  style="background-color: transparent;    border:5px solid #000;  box-shadow: 5px 5px 15px #3D3D3D;" > 
                <b-card-text class="text-center " style="margin:-10px;">   
                   <b-row class="justify-content-center " >
                      <b-col cols="2" md="9"> 
                          <div class="row">
                              <div class="col-12">    
                                  <p class="mt-2">
                                      <span class="h5" style="color:#E0A621;"><b>Intentos:</b> 
                                      {{intentos}}/{{MAXIMOS_INTENTOS}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span class="h5 ml-5" style="color:#019df4;"><b>Aciertos:</b>
                                      {{aciertos}}  </span>
                                  </p>  
                                  <br>
                              </div>
                          </div>
                          <div style="margin-top: -30px;">
                          <div v-for="(fila, indiceFila) in memorama" :key="indiceFila"   class="row justify-content-center ">
                              <div :key="indiceFila+''+indiceImagen" class="col-3"  v-for="(imagen, indiceImagen) in fila" >  
                                      <img @click="voltear(indiceFila, indiceImagen)" :style="imagen.mostrar ? 'cursor: pointer;' : 'cursor: pointer; background: #019df4;'"  
                                          :class="{'girar': imagen.mostrar}"
                                          :src="(imagen.mostrar ? imagen.ruta :
                                          NOMBRE_IMAGEN_OCULTA)" class="mt-2 card-img-top img-fluid
                                          img-thumbnail"  > 
                              </div>
                          </div>
                          </div> 
                          <br>
                      </b-col>
                   </b-row>
                </b-card-text>
              </b-card>
           </b-col> 
           <b-col cols="11" md="9" v-if="empieza == 1 && isMobile">    
              <b-row class="justify-content-center " >
              <b-col cols="12" md="9"> 
                  <div class="row">
                      <div class="col-12 text-center">   
                          <br>
                          <p>
                              <span class="h5" style="color:#29486f;"><b>Intentos:</b> 
                              {{intentos}}/{{MAXIMOS_INTENTOS}}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span class="h5 ml-5" style="color:#fff;"><b>Aciertos:</b>
                              {{aciertos}}  </span>
                          </p>  
                          <br>
                      </div>
                  </div>
                  <div style="margin-top: -30px;">
                  <div v-for="(fila, indiceFila) in memorama" :key="indiceFila"   class="row justify-content-center ">
                      <div :key="indiceFila+''+indiceImagen" class="col-3"  v-for="(imagen, indiceImagen) in fila" > 
                          <br>
                              <img @click="voltear(indiceFila, indiceImagen)" :style="imagen.mostrar ? 'cursor: pointer; ' : 'cursor: pointer; background: #5BC500;'"  
                                  :class="{'girar': imagen.mostrar}"
                                  :src="(imagen.mostrar ? imagen.ruta :
                                  NOMBRE_IMAGEN_OCULTA)" class="card-img-top img-fluid
                                  img-thumbnail"  > 
                      </div>
                  </div>
                  </div> 
                  <br>
              </b-col>
              </b-row> 
           </b-col> 
           <b-col cols="11" md="3" v-if="correcto != 0">    
              <br>
            <b-card  style="background-color: #FFF; border-color: #d6d6d6;   border-radius: 50px;  z-index:1;" class="mt-4"> 
             <b-card-text class="text-center">
              <center>
                  <template v-if="correcto == 2">  
                      <p  :style="isMobile ? 'font-size:25px;' : 'font-size:28px; '" >  
                          <span class="mt-5" style="color:#8d3fe1;"><b>Gracias por participar</b></span> 
                          <br> 
                          <span :style="isMobile ? 'font-size: 20px;' : 'font-size: 22px;'">Suerte para la próxima</span>
                      </p> 
                  </template>
                  <template v-else-if="correcto == 1"> 
                      <img  src="elementos/check.png" class="rounded  float-center" alt="Image"  style="width: 30%;"> 
                      <p  :style="isMobile ? 'font-size:30px;' : 'font-size:40px;'"  >
                      ¡Felicidades! 
                      <br><span :style="isMobile ? 'color:#5cb615; font-size: 25px;' : 'color:#5cb615; font-size: 30px;'"><b>Ganaste <br>10 Pts.</b></span> 
                      </p> 
                  </template>
              </center>
             </b-card-text>
            </b-card>
           </b-col>
         </b-row>
         <template v-else>
           <b-row class="justify-content-center mt-5" >
               <b-col cols="11" md="3"  style="color:#0b2739; font-size: 25px;"> 
                <center> <b-spinner style="color: #0b2739;" v-if="loader" ></b-spinner> Procesando solicitud... </center> 
               </b-col>
           </b-row> 
          </template>
      </div>
      <br><br>
    </div>
  </template> 
  <script>
  const MAXIMOS_INTENTOS = 8, // Intentos máximos que tiene el jugador
      COLUMNAS = 4, // Columnas del memorama
      SEGUNDOS_ESPERA_VOLTEAR_IMAGEN = 1, // Por cuántos segundos mostrar ambas imágenes
      NOMBRE_IMAGEN_OCULTA = "./img/star.png"; // La imagen que se muestra cuando la real está oculta
      import Swal from 'sweetalert2'
  import * as moment from 'moment';
  export default {
    data(){
      return{
          correcto:0,
          countdown: moment.utc(61).format('HH:mm:ss'),
          expires_in: null,
          interval: null,
          Clickinterval: false,
          loader:false,
          isMobile: this.$mobile(),
          empieza:0,
          vertical:0,
          // La ruta de las imágenes. Puede ser relativa o absoluta
          imagenes: [
              "./img/amor.png",
              "./img/cool.png",
              "./img/enojado.png",
              "./img/feliz.png",
              "./img/mareado.png",
              "./img/miedo.png",
          ],
          memorama: [],
          // Útiles para saber cuál fue la carta anteriormente seleccionada
          ultimasCoordenadas: {
              indiceFila: null,
              indiceImagen: null,
          },
          NOMBRE_IMAGEN_OCULTA: NOMBRE_IMAGEN_OCULTA,
          MAXIMOS_INTENTOS: MAXIMOS_INTENTOS,
          intentos: 0,
          aciertos: 0,
          esperandoTimeout: false,
          }
      },
      destroyed () {
          clearInterval(this.interval);
      },
      computed: {
          user() {
          return this.$store.getters.getInfoUser;
          },
      },
      methods: {
           getJuego(){
             this.loader = true;
             var url= 'juego/estatus2?email='+ this.user.email;
              this.$api.get(url).then(
                  ({data}) => {
                      console.log(data);
                      this.loader = false;
                     if (this.isMobile)
                      {
                          this.$bus.$emit("cambi_fo2", true)
                      } 
                      this.correcto = data.correcto;
                      if (data.correcto != 0)
                      {
                          this.empieza = 0;
                          clearInterval(this.interval);    
                      }
                      if(data.correcto == 1){ 
                      this.$confetti.start({
                          particles: [
                              {
                              type: 'rect',
                              },
                              {
                              type: 'circle',
                              },
                          ],
                          defaultColors: [
                              '#5cb617',
                              '#ffffff',
                              '#187abe'
                          ],
                      });
                      setTimeout(() => {
                          this.$confetti.stop();
                      }, 2000);
                      }
                  }
              );
           },
           _setInterval: function() {
              // this.mostrarPregunta = 1;
              if(this.Clickinterval==false){
                  this.interval = setInterval(() => {
                      if (this.expires_in === 0) {
                          this.empieza = 0;
                          clearInterval(this.interval);
                      } else {
                          this.expires_in += 1;
                          if(this.expires_in>0){
                              this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                          }
                      }
                  }, 1000);
                  this.Clickinterval = true;
              }
          },
          empezarCronometro(){  
              this.empieza = 1;
              this.countdown = moment.utc(32).format('HH:mm:ss');
              this.expires_in = 1;
              this._setInterval();
              if (this.isMobile)
               {
                this.$bus.$emit("cambi_fo6", true)
               }
               
              if (window.orientation == '90'  && this.isMobile  || window.orientation == '-90'  && this.isMobile )
              {
                this.vertical = 1; 
                this.$bus.$emit("actualiza_vertical", true)
                 window.scrollTo(0, 300);   
              }else{
                this.vertical = 0; 
                 window.scrollTo(0, 500);     
              } 
          },
          mostrarCreditos() {
              Swal.fire({
                  title: "Acerca de",
                  html: `Creado por <a href="//parzibyte.me/blog">Luis Cabrera Benito</a>
                  <br>
                  <strong>Créditos</strong>
                  <br>
                  Imagen de signo de interrogación: 
                  <div>Icons made by <a href="https://www.flaticon.com/authors/roundicons" title="Roundicons">Roundicons</a> 
                      from <a rel="nofollow" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> 
                      is licensed by 
                      <a rel="nofollow" href="http://creativecommons.org/licenses/by/3.0/" 
                      title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a>
                  </div>
                  `,
                  confirmButtonText: "Cerrar",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
              });
          },
          // Método que muestra la alerta indicando que el jugador ha perdido; después
          // de mostrarla, se reinicia el juego
          indicarFracaso() { 
              this.$api.post('juego/store', {
                      mrt: this.user.mrt,
                      idpdv: this.user.id_pdv,
                      email: this.user.email,
                      seccion: 'Juego',
                      puntos: 0,
                      perfil: this.user.perfil,
                      respuesta: 'Memorama',
                      tiempo: this.countdown,
                      correcto: this.correcto
                  }).then(response => {
                          console.log(response);
                          this.loader = false;  
                          this.empieza = 0;
                          clearInterval(this.interval);
                            this.getJuego();
                      })
                      .catch(error => {
                          console.log(error);
                          this.loader = false;
                      });
           
          },
          // Mostrar alerta de victoria y reiniciar juego
          indicarVictoria() { 
                this.$api.post('juego/store', {
                      mrt: this.user.mrt,
                      idpdv: this.user.id_pdv,
                      email: this.user.email,
                      seccion: 'Juego',
                      puntos: 10,
                      perfil: this.user.perfil,
                      respuesta: 'Memorama',
                      tiempo: this.countdown,
                      correcto: this.correcto
                  }).then(response => {
                          console.log(response);
                          this.loader = false;  
                          this.empieza = 0;
                          clearInterval(this.interval);
                            this.getJuego();
                      })
                      .catch(error => {
                          console.log(error);
                          this.loader = false;
                      });
          },
          // Método que indica si el jugador ha ganado
          haGanado() {
              return this.memorama.every(arreglo => arreglo.every(imagen => imagen.acertada));
          },
          // Ayudante para mezclar un arreglo
          mezclarArreglo(a) {
              var j, x, i;
              for (i = a.length - 1; i > 0; i--) {
                  j = Math.floor(Math.random() * (i + 1));
                  x = a[i];
                  a[i] = a[j];
                  a[j] = x;
              }
              return a;
          },
          // Aumenta un intento y verifica si el jugador ha perdido
          aumentarIntento() {
              this.intentos++;
              if (this.intentos >= MAXIMOS_INTENTOS) {
                this.empieza = 0;
                  this.correcto = 2;
              }
          },
          // Se desencadena cuando se hace click en la imagen
          voltear(indiceFila, indiceImagen) {
              // Si se está regresando una imagen a su estado original, detener flujo
              if (this.esperandoTimeout) {
                  return;
              }
              // Si es una imagen acertada, no nos importa que la intenten voltear
              if (this.memorama[indiceFila][indiceImagen].acertada) {
                  return;
              }
              // Si es la primera vez que la selecciona
              if (this.ultimasCoordenadas.indiceFila === null && this.ultimasCoordenadas.indiceImagen === null) {
                  this.memorama[indiceFila][indiceImagen].mostrar = true;
                  this.ultimasCoordenadas.indiceFila = indiceFila;
                  this.ultimasCoordenadas.indiceImagen = indiceImagen;
                  return;
              }
              // Si es el que estaba mostrada, lo ocultamos de nuevo
              let imagenSeleccionada = this.memorama[indiceFila][indiceImagen];
              let ultimaImagenSeleccionada = this.memorama[this.ultimasCoordenadas.indiceFila][this.ultimasCoordenadas.indiceImagen];
              if (indiceFila === this.ultimasCoordenadas.indiceFila &&
                  indiceImagen === this.ultimasCoordenadas.indiceImagen) {
                  this.memorama[indiceFila][indiceImagen].mostrar = false;
                  this.ultimasCoordenadas.indiceFila = null;
                  this.ultimasCoordenadas.indiceImagen = null;
                  this.aumentarIntento();
                  return;
              }
  
              // En caso de que la haya encontrado, ¡acierta!
              // Se basta en ultimaImagenSeleccionada
              this.memorama[indiceFila][indiceImagen].mostrar = true;
              if (imagenSeleccionada.ruta === ultimaImagenSeleccionada.ruta) {
                  this.aciertos++;
                  this.memorama[indiceFila][indiceImagen].acertada = true;
                  this.memorama[this.ultimasCoordenadas.indiceFila][this.ultimasCoordenadas.indiceImagen].acertada = true;
                  this.ultimasCoordenadas.indiceFila = null;
                  this.ultimasCoordenadas.indiceImagen = null;
                  // Cada que acierta comprobamos si ha ganado
                  if (this.haGanado()) {
                    this.empieza = 0;
                      this.correcto  = 1;
                  }
              } else {
                  // Si no acierta, entonces giramos ambas imágenes
                  this.esperandoTimeout = true;
                  setTimeout(() => {
                      this.memorama[indiceFila][indiceImagen].mostrar = false;
                      this.memorama[indiceFila][indiceImagen].animacion = false;
                      this.memorama[this.ultimasCoordenadas.indiceFila][this.ultimasCoordenadas.indiceImagen].mostrar = false;
                      this.ultimasCoordenadas.indiceFila = null;
                      this.ultimasCoordenadas.indiceImagen = null;
                      this.esperandoTimeout = false;
                  }, SEGUNDOS_ESPERA_VOLTEAR_IMAGEN * 1000);
                  this.aumentarIntento();
              }
          },
          reiniciarJuego() {
              let memorama = [];
              this.imagenes.forEach((imagen) => {
                  let imagenDeMemorama = {
                      ruta: imagen,
                      mostrar: false, // No se muestra la original
                      acertada: false, // No es acertada al inicio
                  };
                  // Poner dos veces la misma imagen
                  memorama.push(imagenDeMemorama, Object.assign({}, imagenDeMemorama));
              });
  
              // Sacudir o mover arreglo; es decir, hacerlo aleatorio
              this.mezclarArreglo(memorama);
  
              // Dividirlo en subarreglos o columnas
              let memoramaDividido = [];
              for (let i = 0; i < memorama.length; i += COLUMNAS) {
                  memoramaDividido.push(memorama.slice(i, i + COLUMNAS));
              }
              // Reiniciar intentos
              this.intentos = 0;
              this.aciertos = 0;
              // Asignar a instancia de Vue para que lo dibuje
              this.memorama = memoramaDividido;
          },
          // Método que precarga las imágenes para que las mismas ya estén cargadas
          // cuando el usuario gire la tarjeta
          precargarImagenes() {
              // Mostrar la alerta
              this.reiniciarJuego 
  
  
              let total = this.imagenes.length,
                  contador = 0;
              let imagenesPrecarga = Array.from(this.imagenes);
              // También vamos a precargar la "espalda" de la tarjeta
              imagenesPrecarga.push(NOMBRE_IMAGEN_OCULTA);
              // Cargamos cada imagen y en el evento load aumentamos el contador
              imagenesPrecarga.forEach(ruta => {
                  const imagen = document.createElement("img");
                  imagen.src = ruta;
                  imagen.addEventListener("load", () => {
                      contador++;
                      if (contador >= total) {
                          // Si el contador >= total entonces se ha terminado la carga de todas
                          this.reiniciarJuego();
                          Swal.close();
                      }
                  });
                  // Agregamos la imagen y la removemos instantáneamente, así no se muestra
                  // pero sí se carga
                  document.body.appendChild(imagen);
                  document.body.removeChild(imagen);
              });
          },
      },
      mounted() {
          this.precargarImagenes(); 
      },
  }
  </script>
   <style>
   #confetti-canvas {
    z-index: 1000;
  }
   </style>